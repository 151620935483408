// import {useEffect, useState}  from 'react';
import { Routes, Route } from 'react-router-dom';

import { AlertProvider } from '../utils/notification/alertcontext';
import Alert from '../utils/notification/alert';

// import Onboarding from '../components/onboarding/index'
import {About, Booking, Career, Home, News, NewsDetail, NotFound, Services} from '../components/pages/_route';
import Navbar from '../components/reuseables/navbar';
import Footer from '../components/reuseables/footer';

const IndexRoutes = () => {
  
  return (
    <div className='bg-white flex flex-col items-center w-full relative'>
      <Navbar/>
      <AlertProvider>
      <Alert />  
      <main className='mt-[3rem] w-full'>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/updates" element={<News/>} />
          <Route path="/updates/:id" element={<NewsDetail/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/careers" element={<Career/>} />
          <Route path="/booking" element={<Booking/>} />
          <Route path="/*" element={<NotFound/>} />
        </Routes>
      </main>
      </AlertProvider>
      <Footer />
    </div>
  )
}

export default IndexRoutes;